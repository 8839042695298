
export default [
    {
        path: '/bookclosure',
        component: "__base",
        otherdata: { color: "caccounting" },
        children: [
            {
                // module: "TDS",
                path: "list",
                component: "Projects.vue",
                meta: { title: "Project List" },
            },
            {
                // module: "TDS",
                path: "view/:id",
                component: "View.vue",
                meta: { title: "View Details" },
            },
            {
                // module: "TDS",
                path: "*",
                component: "",
            },
        ],
    },
]

